<template>
  <div class="text-sm leading-5 text-orange-30">
    <div
      v-if="footer.leftColumn?.content"
      class="font-light max-lg:text-white"
      @click="contentLink"
      v-html="footer.leftColumn.content"
    />
  </div>
</template>

<script setup lang="ts">
const { footer } = useGeneralInternalStore();
const contentLink = useContentLink;
</script>
