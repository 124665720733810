<template>
  <teleport to="body">
    <TransitionRoot
      enter="ease-in-out duration-150"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in-out duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      as="template"
      appear
      :show="generalInternalStore.systemLoader"
    >
      <div
        class="fixed inset-0 top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] z-[999] flex items-center justify-center bg-black/25 backdrop-blur-[2.5px] transition-opacity"
      >
        <img
          src="/images/loader.gif"
          alt="Loader"
          class="h-30 w-30 object-contain grayscale"
        />
      </div>
    </TransitionRoot>
  </teleport>
</template>

<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";

const generalInternalStore = useGeneralInternalStore();
</script>
