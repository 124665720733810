<template>
  <footer class="bg-primary py-9 lg:py-12.5">
    <div class="container">
      <div class="w-full lg:flex">
        <div class="hidden grow lg:block">
          <div class="flex w-full gap-[45px] lg:gap-12.5">
            <CommonFooterLeftColumn v-if="footer.leftColumn" />
            <div
              v-for="(item, i) in mainFooterMenu"
              :key="`parent_link_${i}`"
              class="text-sm leading-5 text-orange-30"
            >
              <template v-if="item.is_title">
                <div class="text-sm font-bold">
                  {{ item?.currentTranslation?.title }}
                </div>
              </template>

              <template v-else-if="item.url.includes('btn-id-')">
                <button
                  :id="extractButtonId(item.url)"
                  class="inline-flex cursor-pointer hover:underline"
                >
                  {{ item?.currentTranslation?.title }}
                </button>
              </template>

              <template v-else>
                <nuxt-link :to="localePath(item.url)" class="text-sm font-bold">
                  {{ item?.currentTranslation?.title }}
                </nuxt-link>
              </template>
              <ul v-if="item.children?.length" class="mt-6 font-light">
                <li
                  v-for="(link, index) in item.children"
                  :key="`link_${i}_${index}`"
                >
                  <template v-if="link.is_title">
                    <div class="inline-flex cursor-default">
                      {{ link?.currentTranslation?.title }}
                    </div>
                  </template>

                  <template v-else-if="link.url.includes('btn-id-')">
                    <button
                      :id="extractButtonId(link.url)"
                      class="inline-flex cursor-pointer hover:underline"
                    >
                      {{ link?.currentTranslation?.title }}
                    </button>
                  </template>

                  <template v-else>
                    <nuxt-link
                      :to="localePath(link.url)"
                      class="inline-flex hover:underline"
                    >
                      {{ link?.currentTranslation?.title }}
                    </nuxt-link>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lg:w-[390px]">
          <nuxt-link
            class="mx-auto mb-14 block w-[282px] max-w-full hover:opacity-80 lg:mx-0 lg:mb-7 lg:w-[324px]"
            :to="localePath('/')"
          >
            <img
              class="object-contain"
              :src="settings?.footer_logo"
              :alt="
                settings?.company_name
                  ? `${settings?.company_name} ${$t('footerLogo')}`
                  : 'Company logo'
              "
            />
          </nuxt-link>
          <CommonSubscriptionFooterForm />
        </div>
      </div>
      <div class="flex-col lg:flex">
        <CommonFooterSocial class="mb-9 mt-9 lg:order-1 lg:mb-0 lg:mt-5" />
        <CommonFooterLeftColumn
          v-if="footer.leftColumn"
          class="mb-2.5 lg:hidden"
        />
        <CommonFooterMobile class="lg:hidden" />
        <div
          class="ui-text-m mt-9.5 text-center text-white lg:text-left lg:text-orange-30"
          v-html="footer.copyright"
        />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePathPolyfill();
const { footerMenu, settings } = useGeneralStore();
const { footer } = useGeneralInternalStore();

const mainFooterMenu = computed(() => {
  return toTree(footerMenu);
});
</script>
