<template>
  <BlocksBlockRichText
    v-if="generalInternalStore.footer.certificatesEntities"
    :data="generalInternalStore.footer.certificatesEntities"
  />
</template>

<script setup lang="ts">
const generalInternalStore = useGeneralInternalStore();
</script>
